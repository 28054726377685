import {
  AdjustmentsIcon,
  ChipIcon,
  DesktopComputerIcon,
  DocumentDuplicateIcon,
  DocumentIcon,
  DocumentReportIcon,
  DocumentTextIcon,
  FingerPrintIcon,
  FolderOpenIcon,
  HomeIcon,
  KeyIcon,
  LogoutIcon,
  MailIcon,
  OfficeBuildingIcon,
  SupportIcon,
  UserCircleIcon,
} from '@heroicons/react/outline';
import { useTranslations } from 'next-intl';
import React from 'react';

import { NavItemProps } from '../types/navigation';
import { OrganisationFeatureKeys } from '../types/organisations';
import useUser from './useUser';

/**
 * @todo
 * - only show portfolio list if FEATURE_PORTFOLIO in org features
 *    - if FEATURE_PORTFOLIO === 'ENABLED' or whatever, show the homepage
 *    - if FEATURE_PORTFOLIO === 'UPSELL' or whatever, show the preview / demo page
 */

const useMainNavItems = (): Record<string, NavItemProps[]> => {
  const t = useTranslations();

  const { userFeatureIsHidden } = useUser();

  const portfolioMgmtIsHidden = userFeatureIsHidden('PORTFOLIO_MANAGEMENT');
  const portfolioReportsHidden = userFeatureIsHidden('PORTFOLIO_REPORTS');

  return {
    primaryNavigation: [
      {
        name: `${t('nav.dashboard')}`,
        href: `/`,
        icon: HomeIcon,
        id: 'dashboard',
      },

      { name: `${t('nav.origination')}`, title: true },
      {
        name: `${t('nav.reports')}`,
        href: '/reports',
        icon: DocumentReportIcon,
        id: 'reports',
      },
      {
        name: `${t('nav.run_one_company')}`,
        href: '/sme-calculator',
        icon: DocumentIcon,
        id: 'sme-calculator',
      },
      {
        name: `${t('nav.run_many_companies')}`,
        href: '/batch-reports',
        icon: DocumentDuplicateIcon,
        id: 'batch-reports',
      },
      // show if eligible
      ...(!portfolioMgmtIsHidden
        ? [
            { name: `${t('nav.portfolio_management')}`, title: true },
            ...(!portfolioReportsHidden
              ? [
                  {
                    name: `${t('nav.portfolio_reports')}`,
                    href: '/portfolio-reports',
                    icon: DocumentTextIcon,
                    id: 'portfolio-reports',
                  },
                ]
              : []),
            {
              name: `${t('nav.portfolios')}`,
              href: '/portfolios',
              icon: FolderOpenIcon,
              id: 'portfolios',
            },
          ]
        : []),
      // removed until being added back in
      // {
      //   name: `${t('sme_prospector')}`,
      //   href: '/sme-prospector',
      //   icon: Document
      // }
    ],
    secondaryNavigation: [
      {
        name: `${t('nav.organisation')}`,
        href: '/organisation',
        icon: OfficeBuildingIcon,
        adminOnly: true,
        id: 'organisation',
      },
      {
        name: `${t('nav.api_documentation')}`,
        href: '/api-documentation',
        icon: ChipIcon,
        id: 'api-documentation',
      },
      {
        name: `${t('nav.settings')}`,
        href: '/settings',
        icon: AdjustmentsIcon,
        id: 'settings',
      },
      {
        name: `${t('nav.support')}`,
        href: '/support',
        icon: SupportIcon,
        id: 'support',
      },
      {
        name: `${t('nav.logout')}`,
        href: '/logout',
        icon: LogoutIcon,
        id: 'logout',
      },
    ],
  };
};

interface ReportNavLink {
  title: string;
  id: string;
  features?: OrganisationFeatureKeys[];
  icon?: any;
}

const useReportNavItems = (
  reportType: 'risk' | 'portfolio' = 'risk'
): ReportNavLink[] => {
  const t = useTranslations();

  if (reportType === 'portfolio') {
    return [
      {
        title: `${t('portfolios.secondary_nav.summary')}`,
        id: 'summary',
        features: [],
      },
      {
        title: `${t('portfolios.secondary_nav.distributions')}`,
        id: 'distributions',
        features: [],
      },
      {
        title: `${t('portfolios.secondary_nav.country_analysis')}`,
        id: 'country_analysis',
        features: [],
      },
      {
        title: `${t('portfolios.secondary_nav.sector_analysis')}`,
        id: 'sector_analysis',
        features: [],
      },
      {
        title: `${t('portfolios.secondary_nav.watchlists')}`,
        id: 'watchlists',
        features: [],
      },
      {
        title: `${t('portfolios.secondary_nav.financial_profile')}`,
        id: 'financial_profile',
        features: [],
      },
      {
        title: `${t('portfolios.secondary_nav.non_financial_profile')}`,
        id: 'non_financial_profile',
        features: [],
      },
    ];
  }

  return [
    { title: `${t('summary')}`, id: 'summary', features: [] },
    {
      title: `${t('risk_metrics')}`,
      id: 'risk_metrics',
      features: [
        'REPORT_RISK_METRIC_TRENDS',
        'REPORT_RISK_OUTLOOK',
        'REPORT_CREDIT_LIMIT',
        'REPORT_RISK_BASED_PRICING',
      ],
    },
    {
      title: `${t('data_sources')}`,
      id: 'data_sources',
      features: [],
    },
    {
      title: `${t('financial_trends')}`,
      id: 'financial_trends',
      features: ['REPORT_FINANCIAL_RATIOS'],
    },
    {
      title: `${t('corporate_governance')}`,
      id: 'corporate_governance',
      features: [
        'REPORT_CORPORATE_GOVERNANCE',
        'REPORT_DIRECTORS',
        'REPORT_SHAREHOLDERS',
      ],
    },
    {
      title: `${t('ownership_structure')}`,
      id: 'ownership_structure',
      features: ['REPORT_CORPORATE_GOVERNANCE'],
    },
    {
      title: `${t('legal_events')}`,
      id: 'legal_events',
      features: ['REPORT_LEGAL_EVENTS'],
    },
    {
      title: `${t('macroeconomic_trends')}`,
      id: 'macro_economic_trends',
      features: ['REPORT_MACROECONOMICS'],
    },
    {
      title: `${t('esg')}`,
      id: 'esg',
      features: ['REPORT_GOVERNANCE', 'REPORT_SECTORS', 'REPORT_ENVIRONMENTAL'],
    },
    { title: `${t('news')}`, id: 'news', features: ['REPORT_NEWS'] },
  ];
};

const useSettingsNavItems = (): ReportNavLink[] => {
  const t = useTranslations();
  return [
    {
      icon: UserCircleIcon,
      title: `${t('personal_information')}`,
      id: 'personal_information',
    },
    { icon: FingerPrintIcon, title: `${t('preferences')}`, id: 'preferences' },
    { icon: KeyIcon, title: `${t('password')}`, id: 'password' },
    { icon: MailIcon, title: `${t('communication')}`, id: 'communication' },
    {
      icon: DesktopComputerIcon,
      title: `${t('table_size')}`,
      id: 'table_size',
    },
  ];
};

export { useSettingsNavItems, useReportNavItems, useMainNavItems };
