/* eslint-disable security/detect-object-injection */
import classNames from 'classnames';
import { ReactNode } from 'react';

import { PlaywrightProps } from '../../types/global';
import LoadingIcon from '../svgs/LoadingIcon';
import Link from './Link';

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'highlight'
  | 'new-highlight'
  | 'alt'
  | 'danger'
  | 'highlight-5'
  | 'none';

export interface ButtonProps extends PlaywrightProps {
  variant: ButtonVariant;
  children: ReactNode;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  newClassName?: string;
  onClick?: (() => void) | ((e?: any) => Promise<void>);
  linkTo?: string;
  type?: 'button' | 'submit' | 'reset';
  isIcon?: boolean;
}

export const buttonClassName = {
  base: 'last:w-full flex justify-center py-2 px-4 border border-transparent rounded shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 items-center',
  primary: 'bg-primary hover:bg-primary/70 focus:ring-primary/70 text-white ', // dark blue
  secondary: 'bg-secondary hover:bg-gray-800 focus:gray-800 text-white ', // black
  alt: 'bg-alt hover:bg-alt/70 focus:bg-alt/70 text-white', // lighter blue
  danger: 'bg-danger hover:bg-red-800 focus:ring-red-800 text-white ', // dark blue
  highlight:
    'bg-highlight hover:bg-highlight/70 focus:ring-yellow-500 text-white ', // orange
  'new-highlight':
    'bg-new-highlight hover:bg-new-highlight/70 focus:ring-blue-500 text-white ', // orange
  'highlight-5':
    'bg-highlight-5 hover:bg-green-500 focus:ring-green-500 text-white ', // orange
  icon: '!h-10 !w-10 !p-0 flex items-center justify-center text-white rounded-none',
  none: '',
};

const disabledClassName = 'opacity-60 pointer-events-none';

const Button = ({
  className,
  newClassName,
  variant,
  loading,
  type = 'button',
  linkTo,
  children,
  disabled,
  playwrightId,
  isIcon,
  ...rest
}: ButtonProps & React.HTMLProps<HTMLButtonElement>) => {
  if (linkTo) {
    return (
      <Link
        linkTo={linkTo}
        className={classNames(
          newClassName
            ? newClassName
            : `${buttonClassName.base} ${
                buttonClassName[variant]
              } ${className} ${isIcon && buttonClassName.icon}`,
          disabled ? disabledClassName : ''
        )}
        {...playwrightId}
        {...rest}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      disabled={disabled}
      type={type}
      className={
        newClassName
          ? newClassName
          : `${buttonClassName.base} ${buttonClassName[variant]} ${className} ${
              disabled ? disabledClassName : ''
            } ${isIcon && buttonClassName.icon}`
      }
      {...playwrightId}
      {...rest}
    >
      {loading && <LoadingIcon className="w-5 h-5" />}
      {!loading && children}
    </button>
  );
};

export default Button;
