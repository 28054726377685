/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-console */
import type { SeverityLevel } from '@sentry/nextjs';
import * as Sentry from '@sentry/nextjs';

import config from '../../config';
import { defaultHeaders } from '../api-handler/headers';

/** From Sentry SDK but types aren't exported */
// type severity SeverityLevel = 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug';
const severityMap: Record<number, SeverityLevel> = {
  100: 'info',
  200: 'info',
  300: 'info',
  400: 'warning',
  500: 'error',
};

export const fetcher = async (
  relativeUrl: string,
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' = 'GET',
  data?: object | null,
  headers?: {
    [key: string]: string;
  }
  // returnType?: 'json' | 'csv'
) => {
  const contentType =
    headers && headers['Content-Type']
      ? headers['Content-Type']
      : 'application/json';

  let res: Response | null = null;

  const info = {
    relativeUrl,
    method,
    data: JSON.stringify(data),
    headers,
    vercel_env: config.NEXT_PUBLIC_VERCEL_URL,
    branch: process.env.NEXT_PUBLIC_GIT_BRANCH,
  };

  const sentryError = {
    info,
    message: 'Failed to fetch data',
    status: 0,
  };

  try {
    res = await fetch(`${config.URL}${relativeUrl}`, {
      method,
      headers: {
        ...defaultHeaders,
        ...(method !== 'GET'
          ? headers
          : {
              'Content-Type': contentType,
            }),
      },
      ...(!['GET', 'HEAD'].includes(method) && data
        ? { body: JSON.stringify(data) }
        : null),
    });

    sentryError.status = res.status;
    sentryError.message = res.statusText;

    if (res.headers.get('content-type')?.includes('application/json')) {
      try {
        return res.json();
      } catch (error) {
        sentryError.message =
          (error as Error)?.message || 'Failed to parse JSON';
      }
    } else if (res.headers.get('content-type')?.includes('application/pdf')) {
      try {
        return res.blob();
      } catch (error) {
        sentryError.message =
          (error as Error)?.message || 'Failed to parse PDF';
      }
    }

    return res;
  } catch (error) {
    // log sentry
    Sentry.withScope(function (scope) {
      scope.setLevel(severityMap[Math.round(res!.status / 100) * 100]);

      Sentry.captureException(sentryError, {
        data: { data: JSON.stringify(data), message: String(error), error },
      });
    });

    // capture error on sentry
    console.log('------------------------------------------------------');
    console.log(`Error making request to ${config.URL}${relativeUrl}`);
    console.error(error);
    console.log('------------------------------------------------------');
    return {
      error: true,
      message: error,
    };
  }
};

export default fetcher;
